<template>
  <div>
    <b-card>
      <div class="button-open-modal" v-show="[1, 2, 3, 5].includes(tabIndex)">
        <b-button
          variant="warning"
          class="push-right"
          @click="() => openModal(null)"
          :disabled="loading.button"
          ><feather-icon icon="PlusIcon" />
          <span v-show="[1, 3, 5].includes(tabIndex)" >
            Nuevo
          </span>

          <span v-show="[2].includes(tabIndex)">
            Nueva credencial
          </span>
        </b-button>
      </div>
      <b-tabs align="left" class="tab-primary" v-model="tabIndex">
        <b-tab>
          <template #title>
            <div class="tab-title">
              <feather-icon icon="SendIcon" />
              <span> {{ $t("Datos generales") }} </span>
            </div>
          </template>
          <generals></generals>
        </b-tab>
        <b-tab lazy>
          <template #title>
            <div>
              <feather-icon icon="FileTextIcon" />
              <span> {{ $t("Servicios") }} </span>
            </div>
          </template>
          <services :formTab="form" :openModal="openModal"></services>
        </b-tab>
        <b-tab lazy>
          <template #title>
            <div>
              <feather-icon icon="LockIcon" />
              <span> {{ $t("Credenciales Courier") }} </span>
            </div>
          </template>
          <credentials :formTab="form" :openModal="openModal"></credentials>
        </b-tab>
      </b-tabs>
      <modal-carrier-services :formTab="form" ></modal-carrier-services>
      <modal-credentials :formTab="form" ></modal-credentials>

    </b-card>
  </div>
</template>
<script>
import Generals from '../generals/Generals.vue'
import Services from '../carrierServices/CarrierServices.vue'
import Credentials from '../credentials/Credentials.vue'
import ModalCredentials from '../credentials/ModalCredentials.vue'
import CarriersService from '../carriers.service'
import ModalCarrierServices from '../carrierServices/ModalCarrierServices.vue'

export default {
  name: 'details-carriers',
  components: {
    Generals,
    Services,
    ModalCarrierServices,
    ModalCredentials,
    Credentials
  },
  data() {
    return {
      form: {},
      tabIndex: 0,
      getServices: null,
      modalStatus: false,
      loading: {
        button: false,
        initial: true
      },
      carriersService: new CarriersService(this)
    }
  },
  watch: {
    tabIndex() {
      this.form = {}
    }
  },
  mounted() {
  },
  methods: {
    openModal(data) {
      if (data) this.form = data
      else this.form = {}
      switch (this.tabIndex) {
      case 1:
        this.$bvModal.show('modalCarrierServices')
        break
      case 2:
        this.$bvModal.show('modalCarrierCredentials')
        break
      default:
        break
      }
    }
  }
}
</script>

<style lang="scss">
.button-open-modal {
  top: -3.4rem !important;
  right: 0rem !important;
  position: absolute !important;
}
</style>
