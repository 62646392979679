<template>
  <div>
    <b-card>
      <div v-show="!loading.first">
        <table-render
          id="table_courier_credentials"
          :schema="schema"
          :rows="rows"
          :actions="actions"
          :loading="loading.carriers"
        >
        </table-render>
      </div>
      <b-skeleton
        height="40px"
        width="100%"
        class="mt-2 mb-2 spacing-label-field"
        v-show="loading.first"
      />
      <div class="table-render-skeleton" v-show="loading.first">
        <b-skeleton-table
          :rows="10"
          :columns="schema.length || 10"
          :table-props="{}"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'config-carriers',
  props: ['formTab', 'openModal'],
  components: {  },
  data() {
    return {
      loading: {
        first: true,
        carriers: false,
        button: false
      },
      schema: [],
      rows: [],
      actions: []
    }
  },
  computed: {
    ...mapGetters({
      data: 'getCredentialsCourier'
    })
  },
  mounted() {
    this.setInitialData()
  },
  watch: {
    data() {
      this.loading.first = false
      this.rows = this.data
    },
    '$route'() {
      this.setInitialData()
    }
  },
  methods: {
    setInitialData() {
      this.loading.first = true
      this.$store.dispatch('fetchService', { name: 'getCredentialsCourier', queryParams: { carrier_code: this.$route.params.code } })

      this.schema = [
        { label: 'Nombre', sortable: true, key: 'name' },
        { label: 'Descripcion', sortable: true, key: 'description' },
        { label: 'Ruta de Credencial', sortable: true, key: 'route' },
        { label: 'Ejemplo', sortable: true, key: 'example' },
        { label: 'Acciones', key: 'actions' }
      ]

      this.actions = [
        {
          action: id => this.openUpdateModal(id), icon: 'FileTextIcon', color: 'success', text: 'Detalles de Couriers'
        },
        { action: id => this.confirmDeleteCredentials(id), icon: 'TrashIcon', color: 'danger', text: 'Eliminar' }
      ]
    },
    openUpdateModal(_id) {
      const modalData = this.rows.filter(({ id }) => id === _id).map(item => (
        {
          route: item.route,
          input_type: item.input_type,
          example: item.example,
          description: item.description,
          id: item.id
        }
      ))[0]
      this.openModal(modalData)
    },
    confirmDeleteCredentials(_id) {
      this.$yesno(this.$t('msg-pregunta-eliminar-elemento'), () => this.deleteCredentials(_id))
    },
    deleteCredentials(id) {
      this.$store.dispatch('fetchService', { name: 'deleteCredentialsCourier', params: { id_credentials: id }, onSuccess: this.successDeleteCredentials })
    },
    successDeleteCredentials() {
      this.$success(this.$t('msg-exito-eliminar'))
      this.setInitialData()
    }
  }
}
</script>

<style lang='scss'>
.carriers-button {
  top: -3.5rem !important;
  right: 0rem !important;
  position: absolute !important;
  z-index: 9;
}
</style>
