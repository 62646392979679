<template>
  <b-modal
    id="modalCarrierCredentials"
    :title="$t(`${editing ? 'Editar' : 'Nueva'} credencial`)"
    modal-class="dialog-900"
    ok-only
    no-close-on-esc
    @close="onClose"
    no-close-on-backdrop
  >
    <form-render
      class="mb-2"
      :fields="fields"
      :key="formRenderAccount"
      :form.sync="form"
      @send="onAccept"
      ref="formRenderCredentialsCourier"
      containerButtonsClass="col-sm-12 col-md-3 container-button">
    </form-render>
    <template #modal-footer>
      <b-button variant="warning" @click="ok">
        <b-spinner v-show="loading.callingService" small></b-spinner>
        {{$t('Guardar')}}
      </b-button>
    </template>
  </b-modal>
</template>

<script>

export default {
  name: 'modal-carrier-credentials',
  props: ['formTab'],
  data() {
    return {
      loading: { callingService: false },
      editing: false,
      form: {
        carrier_id: parseInt(this.$route.params.id)
      },
      fields: [],
      formRenderAccount: 0
    }
  },
  watch: {
    formTab() {
      this.editing = !!this.formTab.id
      const { id, ...rest } = this.formTab
      this.form = {
        ...this.form,
        ...rest
      }
    }
  },
  mounted() {
    this.setInitialData()
  },
  methods: {
    setInitialData() {
      this.fields = [
        { fieldType: 'FieldInput', name: 'route', label: 'Ruta credencial (De aqui se tomara el nombre)', containerClass: 'col-sm-12 col-md-6 container-info', validation: 'required', placeholder : 'Ejemplo : credentials.asd.sdf' },
        { fieldType: 'FieldInput', name: 'description', label: 'Descripcion de credencial', containerClass: 'col-md-6 col-sm-12 container-info' },
        { fieldType: 'FieldInput', name: 'example', label: 'Ejemplo de credencial', containerClass: 'col-md-6 col-sm-12 container-info' },
        { fieldType: 'FieldSelect', name: 'input_type', label: 'Tipo de dato', containerClass: 'col-sm-12 col-md-6 container-info', placeholder: 'Dato de la credencial', options: [{ id: 'string', text: 'String o texto' }, { id: 'number', text: 'Numeros' }, { id: 'json', text: 'JSON' }] }
      ]
    },
    ok(e) {
      e.preventDefault()
      this.$refs.formRenderCredentialsCourier.checkForm()
      this.loading.callingService = true
    },
    onAccept() {
      const queryParams = {
        ...this.form,
        input_type: this.formTab.input_type || this.form.input_type.id
      }
      if (this.editing) {
        const params = { id_credentials: this.formTab.id }
        this.$store.dispatch('fetchService', { name: 'updateCredentialsCourier', params, queryParams, onSuccess: this.succesSaveCredentials })
      } else {
        this.$store.dispatch('fetchService', { name: 'addCredentialsCourier', queryParams, onSuccess: this.succesSaveCredentials })
      }
    },
    onClose() {
      this.form = {}
    },
    succesSaveCredentials() {
      this.loading.callingService = false
      this.$bvModal.hide('modalCarrierCredentials')
      this.$success(this.$t('msg-exito-guardar'))
      this.$store.dispatch('fetchService', { name: 'getCredentialsCourier', queryParams: { carrier_code: this.$route.params.code } })
      this.onClose()
    }
  }
}
</script>
<style>
</style>
